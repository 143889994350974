import React from 'react';

import PropTypes from 'prop-types';

import QuaoteMark from '../../../Atoms/QuoteMark/QuoteMark';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';
import VisuelComponent from '../VisuelComponent/VisuelComponent';

import './IntroPage.scss';

let classNames = require('classnames');

const IntroPage = ({
  title,
  subtitle,
  visuel,
  text,
  size,
  quote,
  customClass,
}) => {
  return (
    <section
      className={classNames(
        'section_content',
        'intro_page_component',
        customClass ? customClass : ''
      )}
    >
      <div className="wrapper_page">
        {title && (
          <TitleSection title={title} description={subtitle} type="line" />
        )}
        {visuel && (
          <VisuelComponent visuel={visuel} extremeLeft={true} alt="" />
        )}
        <div
          className={classNames(
            { cross_wrapper_extreme_right: size === 'border' },
            { empty_visuel: !visuel },
            'text_container'
          )}
        >
          {text && (
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: text }}
            ></div>
          )}
          {quote && (
            <QuaoteMark lang={quote.lang} text={quote.text} name={quote.name} />
          )}
        </div>
      </div>
    </section>
  );
};

IntroPage.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  quote: PropTypes.object,
};

export default IntroPage;
