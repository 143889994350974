import React, { useState, useEffect } from 'react';

import { graphql } from 'gatsby';

import { useIntl } from '../../../../../plugins/publicis-gatsby-plugin-i18n';
import AudioComponent from '../../../../components/AudioComponent/AudioComponent';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import IntroPage from '../../../../components/ContentType/IntroPage/IntroPage';
import TextVisuelCTATwoCol from '../../../../components/ContentType/TextVisuelCTATwoCol/TextVisuelCTATwoCol';
import PageBanner from '../../../../components/PageBanner/PageBanner';
import ScrollPageNav from '../../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../../components/Seo/Seo';
import useMedias from '../../../../hooks/useMedias';
import Layout from '../../../../Layout';
import ShapeBackground from '../../../../Molecules/ShapeBackground/ShapeBackground';
import TitlePage from '../../../../Molecules/TitlePage/TitlePage';
import { removeHtmlTag, getBreadCrumb } from '../../../../Utils/Utils';

import './styles.scss';

let classNames = require('classnames');

const ActivitiesPage = ({ data }) => {
  const intl = useIntl();
  const { getImage } = useMedias();

  const ourActivitiesData = data?.ourActivitiesData?.edges[0]?.node;
  const imagesArray = data?.allImages?.edges;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, ourActivitiesData.path?.alias);
  const metaTags =  ourActivitiesData.metatag;
  let metaTitle = '';
  let metaDesc = '';
  
  metaTags.forEach((meta) => {
    if(meta.attributes.name === 'title'){
        metaTitle = meta.attributes.content;
    }
    if(meta.attributes.name === 'description'){
        metaDesc = meta.attributes.content;
    }
  });

  const [blockList, setBlockList] = useState([]);
  const [innerNavList, setInnerNavList] = useState([]);
  const [allText, setAllText] = useState('');

  let globalText = '';
  let innerNavArr = [];
  let basicBlockCount = 0;
  let i = 1;

  const setInnerNav = (classname_, title, scrollEl) => {
    let t = title?.replace(/<[^>]*>?/gm, '');
    innerNavArr.push({
      title: t,
      section: classname_,
      scrollEl: scrollEl,
    });
    i++;
  };

  useEffect(() => {
    let myList = [];

    ourActivitiesData.relationships.field_blocs.forEach((block, index) => {
      if (block.__typename === 'block_content__basic') {
        setInnerNav(
          'block_' + myList.length + '_section',
          block.field_title?.processed,
          'section'
        );
        myList.push({
          title: block.field_title?.processed,
          visuel: getImage(
            imagesArray,
            block?.relationships?.field_image?.drupal_internal__mid
          ),
          subtitle: block.field_subtitle?.processed,
          text: block?.body?.processed,
          link: block?.field_link?.url,
          linkTitle: block?.field_link?.title,
          imageAlignment: block?.field_alignment,
          imageNoPadding: block?.field_no_padding,
        });
      }
    });

    setInnerNavList(innerNavArr);
    setBlockList(myList);

    setAllText(
      removeHtmlTag(
        `${ourActivitiesData.title}.${ourActivitiesData.field_sous_titre !== null &&
          ourActivitiesData.field_sous_titre !== undefined
          ? ourActivitiesData.field_sous_titre
          : ''
        }.${ourActivitiesData.body?.processed != undefined &&
          ourActivitiesData.body?.processed != null
          ? ourActivitiesData.body?.processed
          : ''
        }.${globalText}`
      )
    );
  }, []);

  return (
    <Layout>
      <Seo
        title={ourActivitiesData?.field_metatag?.title ? ourActivitiesData?.field_metatag?.title : metaTitle}
        description={ourActivitiesData?.field_metatag?.description ? ourActivitiesData?.field_metatag?.description : metaDesc}
      />
      <div className={classNames('page_template', 'page_activities')}>
        <ScrollPageNav data={innerNavList} />
        <PageBanner
          visuel={getImage(
            imagesArray,
            ourActivitiesData?.relationships?.field_image?.drupal_internal__mid
          )}
          scroll={true}
        >
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                //grandParentPage: { title: 'Acceuil', url: '/' },
                parentPage: {
                  title: breadCrumb?.parent?.name,
                  url: breadCrumb?.parent?.link,
                },
                currentPage: {
                  title: breadCrumb?.current?.name,
                  url: breadCrumb?.current?.link,
                },
                locale: ourActivitiesData.langcode,
              }}
            />

            <div
              className={classNames({
                title_audio:
                  allText &&
                  ourActivitiesData.field_version_audio &&
                  allText.length > 30,
              })}
            >
              <TitlePage
                color="color_white"
                title={ourActivitiesData.title}
                description={ourActivitiesData?.body?.processed}
              />
              {allText &&
                ourActivitiesData.field_version_audio &&
                allText.length > 30 && (
                  <AudioComponent
                    text={intl.formatMessage({
                      id: 'detailsActivities.button.listen.label',
                    })}
                    textToRead={allText}
                    color="color_white"
                  />
                )}
            </div>
          </div>
        </PageBanner>
        {ourActivitiesData.relationships.field_blocs.map((block, j) => {
          globalText += `${block.field_title?.processed != undefined &&
            block.field_title?.processed != null
            ? block.field_title?.processed
            : ''
            }.${block.field_subtitle?.processed != undefined &&
              block.field_subtitle?.processed != null
              ? block.field_subtitle?.processed
              : ''
            }.${block.body?.processed != undefined && block.body?.processed != null
              ? block.body?.processed
              : ''
            }`;
          if (block.__typename === 'block_content__block_texte') {
            return (
              <IntroPage
                key={j}
                customClass="topZIndex"
                title={block?.title?.processed === undefined && null}
                visuel={getImage(
                  imagesArray,
                  block?.relationships?.field_image?.drupal_internal__mid
                )}
                size="border"
                text={block?.body?.processed}
              />
            );
          }

          if (blockList.length > 0 && basicBlockCount === 0) {
            basicBlockCount++;
            return (
              <section
                className={classNames('section_content_multiple')}
                key={i}
              >
                <ShapeBackground
                  top="right"
                  color="catskill_white"
                  bottomInfinite={true}
                >
                  <div className="wrapper_page">
                    {blockList.map((block, index) => {
                      globalText += `${block.title != undefined && block.title != null
                        ? block.title
                        : ''
                        }.${block?.subtitle != undefined && block?.subtitle != null
                          ? block?.subtitle
                          : ''
                        }.${block.text != undefined && block.text != null
                          ? block.text
                          : ''
                        }`;
                      return (
                        <section
                          className={classNames(
                            'section_content',
                            'block_' + index + '_section'
                          )}
                          key={index}
                        >
                          <TextVisuelCTATwoCol
                            extremeLeft={
                              block?.imageNoPadding &&
                                block?.imageAlignment === 'left'
                                ? true
                                : false
                            }
                            extremeRight={
                              block?.imageNoPadding &&
                                block?.imageAlignment === 'left'
                                ? false
                                : true
                            }
                            orderInverse={
                              block?.imageAlignment === 'right' ? true : false
                            }
                            visuel={block?.visuel}
                            visuelSize="half"
                            title={{
                              titleSize: 'h2',
                              titleText: block?.title,
                              subtitle: block?.subtitle,
                            }}
                            text={block.text}
                            cta={{
                              ctaText: block?.linkTitle,
                              ctaLink: block?.link,
                            }}
                          />
                        </section>
                      );
                    })}
                  </div>
                </ShapeBackground>
              </section>
            );
          }
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query OurActivitiesTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
  ) {
    ourActivitiesData: allNodePage(
      filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
    ) {
      edges {
        node {
          path {
            alias
          }
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          title
          field_version_audio
          langcode
          relationships {
            field_image {
              drupal_internal__mid
            }
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__basic {
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                  }
                  body {
                    processed
                  }
                  field_alignment
                  field_link {
                    title
                    url
                  }
                  field_no_padding
                  field_subtitle {
                    processed
                  }
                  field_title {
                    processed
                  }
                }
                ... on block_content__block_texte {
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                  }
                  body {
                    processed
                  }
                  field_title {
                    processed
                  }
                }
              }
            }
          }
          field_type
          body {
            processed
          }
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
            }
          }
        }
      }
    }

    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
  }
`;

export default ActivitiesPage;
